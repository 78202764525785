import React from 'react';
import PropTypes from 'prop-types';
import styles from './Loader.module.scss';

const Loader = ({ label }) => {
  const firstLoaderClasses = [styles.loader1, styles.loader].join(' ');
  const subsequentLoaderClasses = [styles.loader2, styles.loader].join(' ');

  return (
    <div className={styles.wrapper}>
      <div className={styles.loaderWrapper}>
        <div className={firstLoaderClasses} />
        <div className={subsequentLoaderClasses} />
        <div className={subsequentLoaderClasses} />
      </div>
      {label && <div className={styles.label}>{label}</div>}
    </div>
  );
};

Loader.propTypes = {
  label: PropTypes.string,
};

Loader.defaultProps = {
  label: null,
};

export default Loader;
